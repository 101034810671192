import moment from 'moment';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import has from 'lodash/has';
import {
  DATE_FORMAT, LOG_TYPE, compactArray, isArrayValue, isFalsyString, isNumberValue, logger,
} from '@emobg/web-utils';
import { BOOKING_TYPES } from '@/constants/bookingTypes';
import GEOFENCE_STATUS from '@/constants/geofenceStatus';
import { BOOKING_STATUS } from '@/constants/bookingStatus.const';
import { BOOKING_USAGE_STATUS } from '@/constants/bookingUsageStatus.const';

export const CLOSE_INTO_FUTURE_GAP = 75;

/**
 * Check if booking type is carsharing
 * @param {string} bookingType
 * @return {boolean}
 */
export const isCarsharing = bookingType => bookingType === BOOKING_TYPES.carsharing;

/**
 * Check if booking type is long distance
 * @param {string} bookingType
 * @return {boolean}
 */
export const isLongDistance = bookingType => bookingType === BOOKING_TYPES.longDistance;

/**
 * Check if booking type is intervention
 * @param {string} bookingType
 * @return {boolean}
 */
export const isIntervention = bookingType => bookingType === BOOKING_TYPES.intervention;

/**
 * Check if booking type is free float
 * @param {string} bookingType
 * @return {boolean}
 */
export const isFreeFloat = bookingType => bookingType === BOOKING_TYPES.freeFloat;

/**
 * Check if booking type is one of Carsharing types
 * @param {string} bookingType
 * @return {boolean}
 */
export const isRegular = bookingType => includes([BOOKING_TYPES.carsharing, BOOKING_TYPES.longDistance], bookingType);

/**
 * Check if now is between (date - gap) and date
 * @param {moment} date
 * @param {number} gap
 * @return {boolean}
 */
export const isCloseIntoFuture = (bookingStart, gap = CLOSE_INTO_FUTURE_GAP) => {
  if (!moment.isMoment(bookingStart) || !bookingStart.isValid()) {
    throw new Error('Booking start is not a valid date');
  }

  if (!isNumberValue(gap)) {
    throw new Error('Gap is not a valid number');
  }

  const closeBookingStart = bookingStart.clone().subtract(gap, 'minutes');

  const now = moment();

  return now.isSameOrAfter(closeBookingStart) && now.isSameOrBefore(bookingStart);
};

/**
 * Check if the vehicle's location status is waiting to retrieve the data. Used in bookings with geofence locations.
 * @param {string} geofenceStatus
 * @return {boolean}
 */
export const isWaitingForLocation = geofenceStatus => geofenceStatus === GEOFENCE_STATUS.waitingForLocation;

/**
 * Check if the vehicle's location status is confirmed. Used in bookings with geofence locations.
 * @param geofenceStatus
 * @return {boolean}
 */
export const isLocationConfirmed = geofenceStatus => geofenceStatus === GEOFENCE_STATUS.locationConfirmed;

/**
 * Check if the vehicle's location status is not confirmed. Used in bookings with geofence locations.
 * @param {string} geofenceStatus
 * @return {boolean}
 */
export const isLocationNotConfirmed = geofenceStatus => geofenceStatus === GEOFENCE_STATUS.locationNotConfirmed;

/**
 * Check if the booking locations are the same.
 * @param {object} booking
 * @return {boolean}
 */
export const isOneWay = booking => {
  const locationUuid = get(booking, 'location.uuid');
  const destinationLocationUuid = get(booking, 'destination_location.uuid');
  return Boolean((locationUuid && destinationLocationUuid)
    && (locationUuid !== destinationLocationUuid));
};

/**
 * Determine if the bookings usage must be displayed.
 * @param {string} bookingStatus
 * @param {string} bookingUsageStatus
 * @return {boolean}
 */
export const displayUsageStatus = (bookingStatus, bookingUsageStatus) => bookingStatus === BOOKING_STATUS.cancelled
    && bookingUsageStatus === BOOKING_USAGE_STATUS.notUsed;

export const bookingDuration = (start, end) => {
  const isStartValid = isString(start) || (moment.isMoment(start) && start.isValid());
  const isEndValid = isString(end) || (moment.isMoment(end) && end.isValid());

  if (!(isStartValid && isEndValid)) {
    throw new Error('Invalid dates');
  }

  const bookingStart = moment(start);
  const bookingEnd = moment(end);

  if (!(bookingStart.isValid() && bookingEnd.isValid())) {
    throw new Error('Invalid dates');
  }

  const minutes = bookingEnd.diff(bookingStart, 'minutes');
  const days = bookingEnd.diff(bookingStart, 'days');

  const isNegative = minutes < 0;

  const minutesAbs = Math.abs(minutes);
  const daysAbs = Math.abs(days);

  const duration = moment.duration(minutesAbs, 'minutes');

  return {
    days: daysAbs,
    hours: duration.hours(),
    minutes: duration.minutes(),
    isNegative,
    duration,
  };
};

/**
 * Check if non intervention booking will start soon
 * @param {object} booking
 * @param {object} operator
 * @return {boolean}
 */
export const isBookingCloseIntoFuture = (booking, operator) => {
  try {
    const bookingType = get(booking, 'cs_booking_use_case.booking_type');
    const closeIntoFuture = get(operator, 'configuration.close_into_future');
    const start = get(booking, 'start', null);
    const bookingStart = moment(start, DATE_FORMAT.filter);
    const date = bookingStart.isValid() ? bookingStart : null;

    const isValidBookingType = !isIntervention(bookingType);
    const isClose = isCloseIntoFuture(date, closeIntoFuture);
    return isValidBookingType && isClose;
  } catch (error) {
    logger.message(error.message, LOG_TYPE.error);
    return false;
  }
};

/**
 * Returns the booking type's abbreviation
 * @param {string} bookingType
 * @return {string}
 */
export const getAbbreviation = bookingType => (isCarsharing(bookingType) && 'cs') || (isLongDistance(bookingType) && 'ld') || (isIntervention(bookingType) && 'in') || '';

/**
 * Returns if booking can get insurance damages upgrade
 * @param {Object} booking
 * @return {Boolean}
 */
export const hasInsuranceUpsell = ({ booking }) => !get(booking, 'insurance_fully_covered')
  && get(booking, 'has_insurance_upsell');

/**
 * @param {object} booking - Booking object. Needed props are { has_insurance_upsell, insurance_fully_covered, start }
 * @param {boolean} hasAnnualInsurance - If user has annual insurance or not
 * @returns {boolean}
 */
export const canUpgradeInsurance = (booking, hasAnnualInsurance = false) => {
  if (!isObject(booking)) {
    throw new Error('Booking is not an object');
  }

  const start = get(booking, 'start');
  const isBookingStarted = moment(start).isValid()
    ? moment().isAfter(start)
    : true;

  return !hasAnnualInsurance && hasInsuranceUpsell({ booking }) && !isBookingStarted;
};

/**
 * Returns if the booking is a prebooking or not
 * @param {Object} Booking
 * @return {Boolean}
 */
export const isPreBooking = booking => get(booking, 'isPreBooking')
  || get(booking, 'isBookingRequest', false)
  || (has(booking, 'booking_uuid') && isFalsyString(get(booking, 'booking_uuid')));

/**
 * Returns booking vehicle seats
 * @param {Object} Booking
 * @param {Boolean} fromAlgolia if it is from Algolia, then change the name of the field
 * @return {Number}
 */
export const getBookingVehicleSeats = ({ booking, fromAlgolia = false }) => {
  let seatsProp = isPreBooking(booking) ? 'vehicleCategory.default_seats' : 'vehicle.seats';
  if (fromAlgolia) {
    seatsProp = isPreBooking(booking) ? 'free_seats' : 'available_seats';
  }
  return isNumberValue(get(booking, seatsProp)) ? get(booking, seatsProp, 0) : 0;
};

/**
 * Returns user uuid for booking counting on booking on behalf feature
 * @param {Object} Booking
 * @return {String}
 */
export const getUserUuid = booking => (get(booking, 'canUseBehalfEmployee') && get(booking, 'isBookingBehalfEmployee')
  ? get(booking, 'driver.uuid')
  : (get(booking, 'user.uuid') || get(booking, 'user_uuid')));

/**
 * Returns user profile uuid for booking counting on booking on behalf feature
 * @param {Object} Booking
 * @return {String}
 */
export const getUserProfileUuid = booking => (get(booking, 'canUseBehalfEmployee') && get(booking, 'isBookingBehalfEmployee')
  ? get(booking, 'driver.business_profile_uuid')
  : get(booking, 'profile.uuid'));

/**
 * Returns booking additional drivers
 * @param {Object} Booking
 * @param {Boolean} fromAlgolia if it is from Algolia, then change the name of the field
 * @return {Array}
 */
export const getAdditionalDrivers = ({ booking, fromAlgolia = false }) => {
  const driversProp = fromAlgolia
    ? 'additional_driver'
    : 'allowed_drivers';

  return isPreBooking(booking)
    ? get(booking, 'drivers') || []
    : compactArray(map(get(booking, driversProp), 'email')) || [];
};

/**
 * Returns booking passengers
 * @param {Object} Booking
 * @param {Boolean} fromAlgolia if it is from Algolia, then change the name of the field
 * @return {Array}
 */
export const getPassengers = ({ booking, fromAlgolia = false }) => {
  const passengersProp = (fromAlgolia || isPreBooking(booking)) ? 'passengers' : 'vehicle_passengers.passengers';
  return isArrayValue(get(booking, passengersProp)) ? get(booking, passengersProp) : [];
};

/**
 * Returns booking passengers
 * @param {Object} Booking
 * @param {Boolean} fromAlgolia if it is from Algolia, then change the name of the field
 * @return {String}
 */
export const getBookingUuid = ({ booking, fromAlgolia = false }) => {
  const uuidProp = !isPreBooking(booking) || fromAlgolia ? 'uuid' : 'booking_uuid';
  return get(booking, uuidProp);
};

/**
 * Return Fleet Type
 * @param {Object} Booking
 * @return {String}
 */
export const getFleetType = ({ booking }) => get(booking, 'fleet') || get(booking, 'cs_booking_use_case.fleet');
